import React from "react";
import Layout from "../components/layout";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import Cta from "../partials/Cta";
import Slider from "../components/Slider";
import { graphql, Script } from "gatsby";
import SEO from "../components/seo";

const QuoteContactContent = ({ data, location }) => {
  const _data =
    data?.allPrismicQuoteContactSuccess?.edges &&
    data?.allPrismicQuoteContactSuccess?.edges[0]
      ? data?.allPrismicQuoteContactSuccess?.edges[0]?.node?.data
      : {};
  const [quotes] = _data?.body?.filter(
    (v) => v.slice_type === "contact_sales_quotes"
  );
  const [brands] = _data?.body?.filter(
    (v) => v.slice_type === "trusted_brands_list"
  );
  return (
    <section>
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="pricing_content !text-left pt-32 md:pt-60 flex flex-wrap flex-col lg:flex-row gap-24">
          <div className="flex-1 text-center lg:text-left">
            <div
              className="h1 font-bold text-4xl md:text-[64px] md:leading-[77px] mb-8"
              data-aos="zoom-y-out"
              data-aos-delay="50"
              dangerouslySetInnerHTML={{ __html: _data?.page_heading.html }}
            />

            <div
              data-aos="zoom-y-out"
              data-aos-delay="50"
              className="contact-success"
              dangerouslySetInnerHTML={{ __html: _data?.description?.html }}
            />
          </div>

          <div
            data-aos="zoom-y-out"
            data-aos-delay="60"
            className="flex-1 xl:flex-[2] w-full self-center"
          >
            <div
              className="meetings-iframe-container"
              data-src="https://meetings-eu1.hubspot.com/leo-leclercq/quote?embed=true"
            ></div>
            <Script
              type="text/javascript"
              defer
              src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
            />
          </div>
        </div>

        <div className="pricing_content pt-16">
          <div
            className="h1 font-bold text-4xl md:text-[64px] md:leading-[77px] mb-8"
            data-aos="zoom-y-out"
            data-aos-delay="50"
            dangerouslySetInnerHTML={{ __html: _data?.quote_heading.html }}
          />
        </div>

        <div
          data-aos="zoom-y-out"
          data-aos-delay="80"
          className="pb-12 md:pb-20 flex flex-col md:flex-row items-center justify-center"
        >
          <div data-aos="zoom-y-out" data-aos-delay="60" className="flex-1">
            <Slider myData={quotes?.items} />
          </div>
          <div className="flex-1">
            <a
              href="https://www.g2.com/products/encord/reviews?utm_source=review-widget/"
              title="Read reviews of Encord on G2"
              className="flex mx-auto max-w-[230px]"
            >
              <img
                width={0}
                height={0}
                className="mx-auto w-auto h-auto"
                alt="Read Encord reviews on G2"
                src="https://images.prismic.io/encord/82ebca44-b893-4ab5-86dc-4db417f082c5_gray-10.png?auto=compress,format"
                loading="lazy"
              />
            </a>
            <Script id="redit-pixel-tracking-script" defer>
              {`(function(a,b,c,d){window.fetch("https://www.g2.com/products/encord/rating_schema.json").then(e=>e.json()).then(f=>{c=a.createElement(b);c.type="application/ld+json";c.text=JSON.stringify(f);d=a.getElementsByTagName(b)[0];d.parentNode.insertBefore(c,d);});})(document,"script");`}
            </Script>
          </div>
        </div>

        <div
          data-aos="zoom-y-out"
          data-aos-delay="150"
          className="my-8 flex lex-1"
        >
          <section className="relative">
            <div
              className={`relative max-w-6xl mx-auto px-4 sm:px-6 pb-10 md:pb-10`}
            >
              <p className="text-center text-base font-semibold text-gray-500">
                Trusted by pioneering AI teams - from startups to enterprise
              </p>

              <div
                className={`mt-6 grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-6`}
              >
                {brands.items?.map((x, i) => {
                  return (
                    <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                      <img
                        src={x?.brand_logo?.url}
                        alt={x?.brand_logo?.alt || `brand_icon_${i}`}
                        className="object-scale-down h-24 w-96"
                        loading="lazy"
                        width={0}
                        height={0}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
        <Cta location={location} pageName={"Quote Contact Successs Page"} />
      </div>
    </section>
  );
};

const ContactSuccess = (props) => {
  return (
    <Layout location={props?.location}>
      <LeftColours />
      <RightColours />
      <QuoteContactContent {...props} />
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicQuoteContactSuccess {
      edges {
        node {
          data {
            body {
              ... on PrismicQuoteContactSuccessDataBodyContactSalesQuotes {
                id
                items {
                  icon {
                    alt
                    url
                    gatsbyImageData(width: 400, placeholder: BLURRED)
                  }
                  quote {
                    html
                    text
                  }
                }
                slice_type
              }
              ... on PrismicQuoteContactSuccessDataBodyTrustedBrandsList {
                id
                items {
                  brand_logo {
                    alt
                    url
                    gatsbyImageData(width: 400, placeholder: BLURRED)
                  }
                }
                slice_type
              }
            }
            description {
              text
              html
            }
            page_heading {
              html
              text
            }
            quote_heading {
              html
              text
            }
          }
        }
      }
    }
  }
`;

export const Head = () => {
  return (
    <SEO
      title="Thanks for getting in touch | Encord"
      description="Thanks for booking a time for your Encord, the active learning platform for computer vision."
    />
  );
};

export default ContactSuccess;
